import {useState, useEffect, useRef} from 'react'
import {storyblokEditable} from '@storyblok/react'
import {useSearch} from '@/Components/Search/SearchContext'

export default ({blok}) => {
	const searchClient = useSearch()
	const [styles, setStyles] = useState([])

	const index = searchClient.initIndex('frames_styles');
	const slider = useRef(null);

	useEffect(() => {
		index.search('', {
			filters: `frame.categories:"${blok.collection}"`,
			hitsPerPage: 15,
		}).then(({hits}) => {
			setStyles(hits)
		})
	}, []);

	const [isActive,setIsActive] = useState(false);
	const [isMobile,setIsMobile] = useState(window.innerWidth < 1000);
	const handleResize = () => {
		setIsMobile(window.innerWidth < 1000);
	};
	useEffect(() => {
		window.removeEventListener('resize', handleResize);
		window.addEventListener('resize', handleResize);
	}, []);
	useEffect(() => {
		if (!styles || !styles.length) return;
		let totalWidth = 0;
		for (let i = 0; i < styles.length; i++) {
			totalWidth += isMobile ? 252 : 334;
		}
		if (totalWidth > slider.current.clientWidth) setIsActive(true)
	}, [styles]);

	function imageExists(url, callback) {
		let img = new Image();
		img.onload = function() { callback(true); };
		img.onerror = function() { callback(false); };
		img.src = url;
	}

	return (
		<section className={"trending-styles multiSlider" + (isActive ? ' active' : '')} {...storyblokEditable(blok)}>
			<div className="home-slider">
				<div className="home-slider-top">
					<div className="home-slider-category">
						<h4 className="home-slider-subtitle">{blok.collection}</h4>
					</div>
					<div className="home-slider-control multiSliderControl">
						<button className="home-slider-control-btn prev" disabled>
							<svg>
								<use xlinkHref="#slider-prev-black"></use>
							</svg>
							<svg>
								<use xlinkHref="#slider-prev"></use>
							</svg>
						</button>
						<button className="home-slider-control-btn next">
							<svg>
								<use xlinkHref="#slider-next-black"></use>
							</svg>
							<svg>
								<use xlinkHref="#slider-next"></use>
							</svg>
						</button>
					</div>
				</div>
				<div className="glasses-slides iconic multiSlides" ref={slider}>
					{styles.map(hit => {
						let img = hit.image_url;
						if (!img) return;
						imageExists(img, function(exists) {
							if (!exists) {
								img = '/img/stock.svg'
							}
						});
						return (
							<a className="glasses-slide glasses-item multiSlide" href={hit.url} key={hit.id}>
								<div className="glasses-item-img">
									<img src={img} alt={hit.frame.long_name}/>
								</div>
								<div className="product-item-name">{hit.brand.brand_name}</div>
							</a>
						)}
					)}
				</div>
				<div className="home-slider-indicator"></div>
			</div>
		</section>
	)
}
