import './bootstrap';
import './front';
import '../css/app.css';
import '../css/devcube.css';
import React from 'react'
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

import {apiPlugin, storyblokInit} from "@storyblok/react";
import ActivityBlock from "@/Components/Home/ActivityBlock.jsx";
import ActivityGrid from "@/Components/Home/ActivityGrid.jsx";
import Banner from "@/Components/Banner.jsx";
import BannerTop from "@/Components/BannerTop.jsx";
import CategoryBlock from "@/Components/CategoryBlock.jsx";
import CategoryGrid from "@/Components/CategoryGrid.jsx";
import CenterHero from "@/Components/CenterHero.jsx";
import CollectionSlider from "@/Components/Home/CollectionSlider.jsx";
import ContentPage from "@/Components/ContentPage.jsx";
import FAQ from "@/Components/FAQ.jsx";
import GlassesSlider from "@/Components/Home/GlassesSlider.jsx";
import HighlightedBrands from "@/Components/Home/HighlightedBrands.jsx";
import Tag from "@/Components/Home/Tag.jsx";
import TagList from "@/Components/Home/TagList.jsx";
import Welcome from "@/Components/Home/Welcome.jsx";
import WelcomeColumn from "@/Components/Home/WelcomeColumn.jsx";
import WelcomeBlock from "@/Components/Home/WelcomeBlock.jsx";
import Grid from "@/Components/Grid.jsx";
import LinksGrid from "@/Components/LinksGrid.jsx";
import Hero from "@/Components/Hero.jsx";
import Intro from "@/Components/Intro.jsx";
import StoreIntro from "@/Components/Store/Intro.jsx";
import StoreStaff from "@/Components/Store/Staff.jsx";
import Story from "@/Components/Story.jsx";
import StoryGrid from "@/Components/StoryGrid.jsx";
import FeaturedStory from "@/Components/FeaturedStory.jsx";

storyblokInit({
  accessToken: import.meta.env.VITE_STORYBLOK_TOKEN,
  cache: {
    clear: "manual",
    type: "memory"
  },
  use: [apiPlugin],
  components: {
    'activity-block': ActivityBlock,
    'activity-grid': ActivityGrid,
    'banner': Banner,
    'banner-top': BannerTop,
    'category-block': CategoryBlock,
    'category-grid': CategoryGrid,
    'center-hero': CenterHero,
    'collection-slider': CollectionSlider,
    'content-page': ContentPage,
    'faq': FAQ,
    'glasses-slider': GlassesSlider,
    'highlighted-brands': HighlightedBrands,
    'tag': Tag,
    'tag-list': TagList,
    'welcome': Welcome,
    'welcome-column': WelcomeColumn,
    'welcome-block': WelcomeBlock,
    'grid': Grid,
    'links-grid': LinksGrid,
    'hero': Hero,
    'intro': Intro,
    'store-intro': StoreIntro,
    'store-staff': StoreStaff,
    'story': Story,
    'story-grid': StoryGrid,
    'featured-story': FeaturedStory,
  },
  apiOptions: {
    region: "us"
  },
});

Bugsnag.start({
    apiKey: '311cf6a0415b23c1182c1754a941ac1e',
    plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: '311cf6a0415b23c1182c1754a941ac1e' })

const appName = import.meta.env.VITE_APP_NAME || 'Cool Frames';

createInertiaApp({
  title: (title) => {
    if (! title) {
      return appName
    }
    return `${title} - ${appName}`
  },
  resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
  setup({ el, App, props }) {
    //el.classList.add('h-full')
    const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
    createRoot(el).render(
        <ErrorBoundary>
            <App {...props} />
        </ErrorBoundary>
    )
  }
}).then(() => {
  document.getElementById('app').removeAttribute('data-page')
});
